import React from 'react';
import {
  Disclaimer,
  Footer,
  useGetAppInitDetails,
  useMobileAppDownloadLinks,
} from '@leagueplatform/highmark-common';
import { Image } from '@leagueplatform/genesis-commons';
import { useFooterLinks } from 'hooks/use-footer-links';
import { APP_STORE_PROD_URL, PLAY_STORE_PROD_URL } from 'common/constants';
import { SkeletonBox } from '@leagueplatform/web-common-components';

export const AllMyHealthFooter = React.memo(() => {
  const { footerLinks } = useFooterLinks();
  const { mobileAppDownloadLinks } = useMobileAppDownloadLinks(
    APP_STORE_PROD_URL,
    PLAY_STORE_PROD_URL,
  );
  const { isLoading, appMessaging, brandingInfo } = useGetAppInitDetails();

  if (isLoading)
    return (
      <SkeletonBox
        borderRadius="none"
        height="200px"
        backgroundColor="surface.background.secondary"
      />
    );

  return (
    <Footer
      branding={
        <Image
          src={brandingInfo?.brandingLogo}
          alt="AllMyHealth Branding"
          marginBottom="twoAndHalf"
          width="170px"
        />
      }
      disclaimer={
        <Disclaimer hasLegalModal disclaimer={appMessaging?.disclaimer} />
      }
      footerAppDownloadAssets={mobileAppDownloadLinks}
      footerLinks={footerLinks}
    />
  );
});
