import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import allMyHealthLogo from 'assets/allmyhealth-logo.svg';
import {
  AboutPage,
  useGetAppInitDetails,
} from '@leagueplatform/highmark-common';
import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';

export const AboutAllMyHealth = () => {
  const { formatMessage } = useIntl();
  const { isLoading, appMessaging } = useGetAppInitDetails();
  return (
    <AboutPage
      isLoading={isLoading}
      heading={formatMessage({ id: 'ABOUT_ALLMYHEALTH' })}
      image={{ src: allMyHealthLogo }}
      body={
        <StackLayout spacing="$threeQuarters">
          <ParagraphText>
            {formatMessage({ id: 'ABOUT_ALLMYHEALTH_BODY_1' })}
          </ParagraphText>
          {appMessaging?.disclaimer && (
            <ParagraphText>{appMessaging.disclaimer}</ParagraphText>
          )}
        </StackLayout>
      }
    />
  );
};
