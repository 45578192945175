import { useIntl } from '@leagueplatform/locales';
import {
  FooterLinkProps,
  APP_MANAGED_ROUTES,
} from '@leagueplatform/highmark-common';
import { MEDICAL_POLICY_URL, SAPPHIRE_URL } from 'common/constants';

export const usePreLoginFooterLinks = () => {
  const { formatMessage } = useIntl();

  const footerLinks: FooterLinkProps[] = [
    {
      text: formatMessage({ id: 'MEDICAL_POLICY' }),
      url: MEDICAL_POLICY_URL,
    },
    {
      text: formatMessage({ id: 'FIND_A_DOCTOR' }),
      url: SAPPHIRE_URL,
    },
    {
      text: formatMessage({ id: 'TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES' }),
      url: APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES,
      linkProps: {
        target: '_self',
      },
    },
  ];

  return { footerLinks };
};
