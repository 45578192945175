import {
  APP_MANAGED_ROUTES,
  FEATURE_FLAGS,
  FooterLinkProps,
  useGetAppInitDetails,
  useIsAuthenticated,
} from '@leagueplatform/highmark-common';
import { useIntl } from '@leagueplatform/locales';
import { useGetAppFeatureFlag } from './use-get-app-feature-flag.hook';

export const useFooterLinks = () => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useIsAuthenticated();
  const { eligibilityInfo } = useGetAppInitDetails({
    enabled: !!isAuthenticated,
  });

  const { isEnabled: isLegalPageEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.LEGAL_PAGE,
  );
  const legalUrl = APP_MANAGED_ROUTES.LEGAL;

  const isPostAuth = isAuthenticated && eligibilityInfo?.eligible;

  // TODO update URLs to valid destinations, including non postAuth legal link: HMRK-5763
  const footerLinks: FooterLinkProps[] = isPostAuth
    ? [
        {
          text: formatMessage({ id: 'LEGAL' }),
          url: isLegalPageEnabled ? legalUrl : '',
          linkProps: {
            target: '_self',
          },
        },
        {
          text: formatMessage({ id: 'ALL_LANGUAGES' }),
          url: APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES,
          linkProps: {
            target: '_self',
          },
        },
      ]
    : [];

  return { footerLinks };
};
