import React from 'react';
import {
  AuthedWebviewModal,
  FullScreenContainer,
  LinkHandler,
  SlideData,
  WalkthroughLayout,
} from '@leagueplatform/highmark-common';
import { Box } from '@leagueplatform/genesis-commons';
import account from 'assets/walkthrough-slides/complete-account.svg';

import { useGetWalkthroughSlideContent } from './use-get-walkthrough-slide-content.hook';

export const WalkthroughContainer = ({
  onCompleteWalkthrough,
}: {
  onCompleteWalkthrough: () => void;
}) => {
  const slideData: SlideData = useGetWalkthroughSlideContent();

  return (
    <FullScreenContainer backgroundColor="surface.background.primary">
      <Box>
        <WalkthroughLayout
          slideData={slideData}
          numberOfSlides={5}
          formImage={account}
          hasBrandSlide={false}
          onCompleteWalkthrough={onCompleteWalkthrough}
        />
      </Box>
      <AuthedWebviewModal linkHandler={LinkHandler} />
    </FullScreenContainer>
  );
};
