import { useIntl } from '@leagueplatform/locales';
import care from 'assets/walkthrough-slides/care.svg';
import journey from 'assets/walkthrough-slides/journey.svg';
import profile from 'assets/walkthrough-slides/profile.svg';
import benefits from 'assets/walkthrough-slides/benefits.svg';
import { SlideData } from '@leagueplatform/highmark-common';

export const useGetWalkthroughSlideContent = () => {
  const { formatMessage } = useIntl();

  const slideImages: { [index: number]: string } = {
    1: journey,
    2: benefits,
    3: care,
    4: profile,
  };

  const slideHeadingContents: { [index: number]: string } = {
    1: formatMessage({ id: 'APP_WALKTHROUGH_1_HEADING' }),
    2: formatMessage({ id: 'APP_WALKTHROUGH_2_HEADING' }),
    3: formatMessage({ id: 'APP_WALKTHROUGH_3_HEADING' }),
    4: formatMessage({ id: 'APP_WALKTHROUGH_4_HEADING' }),
  };

  const slideBodyContents: { [index: number]: string } = {
    1: formatMessage({ id: 'APP_WALKTHROUGH_1_BODY' }),
    2: formatMessage({ id: 'APP_WALKTHROUGH_2_BODY' }),
    3: formatMessage({ id: 'APP_WALKTHROUGH_3_BODY' }),
    4: formatMessage({ id: 'APP_WALKTHROUGH_4_BODY' }),
  };

  const slideData: SlideData = {
    slideImages,
    slideHeadingContents,
    slideBodyContents,
  };

  return slideData;
};
