import * as React from 'react';
import {
  FEATURE_FLAGS,
  Navbar,
  useGetAppInitDetails,
  useInjectCobrowseScript,
} from '@leagueplatform/highmark-common';
import { getUserDropdownConfig } from 'utils/get-user-dropdown-config';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';

export const AppNavBarComponent = React.memo(() => {
  const { qualifiers, brandingInfo } = useGetAppInitDetails();
  const { isEnabled: isCobrowseEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.USER_DROPDOWN_COBROWSE,
  );
  useInjectCobrowseScript(isCobrowseEnabled);
  const userDropdownConfig = getUserDropdownConfig({
    ...qualifiers,
    isCobrowseEnabled,
  });

  return (
    <Navbar
      userDropdownConfig={userDropdownConfig}
      brandingInfo={brandingInfo}
    />
  );
});
