import League, {
  getModulePath,
  LEAGUE_MODULE_NAMES,
} from '@leagueplatform/core';
import { BrowserRouter, Route, Switch } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AboutMeRoutes,
  AchievementsRoutes,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  ClaimsRoutes,
  HealthJourneyRoutes,
  HealthProfileRoutes,
  HomePage,
  LiveChatPage,
  MessagingRoutes,
  SupportPage,
  WalletRoutes,
} from 'components/lazy-routes.component';
import React, { Suspense } from 'react';
import {
  APP_MANAGED_ROUTES,
  AuthSessionContainer,
  AuthSignIn,
  AuthSignOut,
  BasePathRedirect,
  CONTENT_ROUTES,
  ERROR_TYPES,
  ErrorState,
  HsaPage,
  LoadingSpinner,
  PageBlockerContainer,
} from '@leagueplatform/highmark-common';
import { FormsLibraryRoutes } from '@leagueplatform/engen-capability-forms-web';
import { MASONRY_ROUTES, MasonryPDF } from '@leagueplatform/masonry';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { useOnlineStatus } from '@leagueplatform/web-common';
import hsaAccountCreatedImage from 'assets/hsa/hsa-account-created.svg';
import openHsaImage from 'assets/hsa/open-hsa.svg';
import { PrivateRoutesContainer } from './components/private-routes-container.component';
import { initializeSDKConfig } from './init-sdk-config';
import { BrandedLandingPage } from './pages/branded-landing.page';
import { AboutAllMyHealth } from './pages/settings/about-allmyhealth.page';
import { LegalPageRoutes } from './pages/legal-page-routes.page';
import { AllMyHealthLanguagePage } from './pages/static/allmyhealth-language.page';

initializeSDKConfig();

export function App() {
  const { isOnline, isOffline } = useOnlineStatus();

  return (
    <League.LeagueProvider>
      <ObservabilityErrorBoundary
        fallback={<ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />}
        errorContext={{
          context: {
            Context: { location: 'Root App ErrorBoundary' },
          },
        }}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {isOffline && (
            <ErrorState isFullPage errorType={ERROR_TYPES.NO_CONNECTION} />
          )}
          {isOnline && (
            <BrowserRouter>
              <Switch>
                <Route
                  path={APP_MANAGED_ROUTES.SIGN_IN}
                  component={AuthSignIn}
                />
                <Route
                  path={APP_MANAGED_ROUTES.SIGN_OUT}
                  component={AuthSignOut}
                />
                <Route
                  path={
                    APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES
                  }
                  component={AllMyHealthLanguagePage}
                />
                <Route path="/" exact component={BrandedLandingPage} />
                <AuthSessionContainer>
                  <PrivateRoutesContainer>
                    {/* TODO: Remove Temp Redirect routes once all platforms don't use this: https://everlong.atlassian.net/browse/HIGHMARK-2809 */}
                    <Route path={APP_MANAGED_ROUTES.CARE_REDIRECT}>
                      <BasePathRedirect
                        targetBasePath={getModulePath(
                          LEAGUE_MODULE_NAMES.careDiscovery,
                        )}
                      />
                    </Route>
                    <Route path={APP_MANAGED_ROUTES.WALLET_REDIRECT}>
                      <BasePathRedirect
                        targetBasePath={getModulePath(
                          LEAGUE_MODULE_NAMES.wallet,
                        )}
                      />
                    </Route>
                    <Route path={APP_MANAGED_ROUTES.MEMBER_REDIRECT}>
                      <BasePathRedirect targetBasePath="" />
                    </Route>
                    <Route
                      path={APP_MANAGED_ROUTES.HOME}
                      exact
                      component={HomePage}
                    />
                    <Route path={MASONRY_ROUTES.PDF_GENERATION}>
                      <MasonryPDF loader={<LoadingSpinner />} />
                    </Route>
                    <Route path={APP_MANAGED_ROUTES.HSA_ENROLLMENT} exact>
                      <HsaPage
                        openHsaImage={openHsaImage}
                        hsaAccountCreatedImage={hsaAccountCreatedImage}
                      />
                    </Route>
                    <Route
                      path={[
                        getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                        getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                        getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                        getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                      ]}
                    >
                      <PageBlockerContainer
                        contentRoute={CONTENT_ROUTES.JOURNEY}
                      >
                        <HealthJourneyRoutes />
                      </PageBlockerContainer>
                    </Route>
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                      component={AboutMeRoutes}
                    />
                    <Route
                      path={getModulePath(
                        LEAGUE_MODULE_NAMES.rewardsAchievements,
                      )}
                      component={AchievementsRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                      component={WalletRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                      component={ClaimsRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                      component={HealthProfileRoutes}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                    >
                      <PageBlockerContainer contentRoute={CONTENT_ROUTES.CARE}>
                        <CareCollectionsRoutes />
                      </PageBlockerContainer>
                    </Route>
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                    >
                      <PageBlockerContainer contentRoute={CONTENT_ROUTES.CARE}>
                        <CareDiscoveryRoutes />
                      </PageBlockerContainer>
                    </Route>
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
                      component={MessagingRoutes}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.SUPPORT}
                      exact
                      component={SupportPage}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.ABOUT}
                      component={AboutAllMyHealth}
                    />
                    <Route
                      path={getModulePath(LEAGUE_MODULE_NAMES.liveChat)}
                      component={LiveChatPage}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.LEGAL}
                      component={LegalPageRoutes}
                    />
                    <Route
                      path={APP_MANAGED_ROUTES.FORMS_LIBRARY}
                      component={FormsLibraryRoutes}
                    />
                  </PrivateRoutesContainer>
                </AuthSessionContainer>
              </Switch>
            </BrowserRouter>
          )}
        </Suspense>
        <ReactQueryDevtools />
      </ObservabilityErrorBoundary>
    </League.LeagueProvider>
  );
}
